import React, { useState } from 'react';
import './header.css';
import './contact.css';
import { Link } from 'react-router-dom';
import { FaLocationDot } from "react-icons/fa6";
import { RiContactsLine } from "react-icons/ri";
import { IoMdContact } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import contactImage from './contactImage.png'; // Import your image here
import LetterByLetter from './LetterByLetter';

function ContactUs() {
  const [isLocVisible, setIsLocVisible] = useState(false);
  const [isContactInfoVisible, setIsContactInfoVisible] = useState(false);
  const [isMapsVisible, setIsMapsVisible] = useState(false);

  const handleClickContactInfo = () => {
    setIsLocVisible(prevState => !prevState);
    setIsContactInfoVisible(prevState => !prevState);
  };

  const handleClickLocationInfo = () => {
    setIsMapsVisible(prevState => !prevState);
  };

  return (
    <div>
      <div className='menu'>
        <Link className='item01' to="/"><p className='item1'>Home</p></Link>
        <Link className='item02' to="/contact"><p className='item2'>Contact Us</p></Link>
        <Link className='item03' to="/gallery"><p className='item3'>Gallery</p></Link>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className='image-container'>
          <img src={contactImage} alt="Contact" className='contact-image' />
        </div>
        <div className='back1'></div>
      <div className='info'>
        <div className='container'>
          <div>
            <div className='cont1' onClick={handleClickContactInfo}>
              <h1>CONTACT INFO</h1>
              <RiContactsLine className='locicon1' size={50} />
            </div>
          </div>
          <div className="vertical-line"></div>
        </div>
        <div className={`loc ${isLocVisible ? 'visible' : ''}`}>
        </div>
        <div className={`contactInfo ${isContactInfoVisible ? 'visible' : ''}`}>
          <div className='title3'>
            <h1>OWNER</h1>
            <IoMdContact className='conticon1' size={50} />
            <p className='click4'><FaPhoneAlt className='phonei1' size={20} />91+ 9449646119</p>
          </div>
          <div className='title2'>
            <h1>MANAGER</h1>
            <IoMdContact className='conticon2' size={50} />
            <p className='click3'><FaPhoneAlt className='phonei2' size={20} />91+ 9980742698</p>
          </div>
        </div>
        <hr className='hrline5'/>
        <div className='container1'>
          <div>
            <iframe className={'maps'}
              title="Google Maps Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0984309392197!2d77.47488167491711!3d12.901391887407556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3f9ff898e053%3A0x21cee887a25c6363!2sSST%20Convention%20Hall!5e0!3m2!1sen!2sin!4v1719369929747!5m2!1sen!2sin"
              width="850"
              height="450"
              allowfullscreen=" "
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
          <div className="vertical-line"></div>
        </div>
        <div>
          <div className='cont' onClick={handleClickLocationInfo}>
            <h1>LOCATION</h1>
            <FaLocationDot className='locicon' size={40} />
          </div>
        </div>
        <div className='blank'></div>
        <div className='letter'>
        <LetterByLetter text="A Marriage Made In Heaven May Start With A Beautiful Place On Earth" speed={100} />
        </div>
        <div className='footer'>
        <div className='image-container1'>
          <img src={contactImage} alt="Contact" className='contact-image1' />
        </div>
        <h1>SST CONVENTION HALL</h1>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
