import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Gallery from './GalleryPage'; 
import ContactUs from './ContactUs';
import HeaderJs from './Header.js'; // Correct import with capitalized first letter

function App() {
  return (
    <>
      <Router>
      <div>
        <Routes>
          <Route path="/" element={<HeaderJs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<ContactUs/>}/>
        </Routes>
      </div>
    </Router>
    </>
  );
}

export default App;
