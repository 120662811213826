import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, isLoading: true };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, isLoading: false };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
    }

    componentDidMount() {
        // Simulating loading state, should be updated with actual loading logic
        this.setState({ isLoading: false });
    }

    render() {
        const { hasError, isLoading } = this.state;

        if (isLoading) {
            return <h3>Loading Instagram post...</h3>;
        }

        if (hasError) {
            return <h3>Something went wrong while loading the Instagram post.</h3>;
        }

        return this.props.children; 
    }
}

export default ErrorBoundary;
