import React, { useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import './gallery.css';
import { Link } from 'react-router-dom';
import contactImage from './contactImage.png';
import { MdPhotoAlbum } from "react-icons/md";
import videoFile from './video1.mp4';
import LazyImage from './LazyImage'; // Import the new component

// Import optimized images
import photo1 from './photo1.webp';
import photo2 from './photo2.webp';
import photo3 from './photo3.webp';
import photo4 from './photo4.webp';
import photo5 from './photo5.webp';
import photo6 from './photo6.webp';
import photo7 from './photo7.webp';
import photo8 from './photo8.webp';
import photo9 from './photo9.webp';
import photo10 from './photo10.webp';
import photo11 from './photo11.webp';
import photo12 from './photo12.webp';
import photo13 from './photo13.webp';

const photos = [
  { src: photo1, width: 800, height: 900 },
  { src: photo2, width: 800, height: 900 },
  { src: photo6, width: 800, height: 800 },
  { src: photo4, width: 800, height: 900 },
  { src: photo5, width: 800, height: 750 },
  { src: photo3, width: 800, height: 900 },
  { src: photo7, width: 800, height: 600 },
  { src: photo8, width: 800, height: 600 },
  { src: photo9, width: 800, height: 900 },
  { src: photo10, width: 800, height: 500 },
  // Add more photos here with their dimensions
];
const photos1 = [
  { src: photo11, width: 800, height: 200 },
  { src: photo3, width: 800, height: 900 },
  { src: photo13, width: 800, height: 800 },
  { src: photo4, width: 800, height: 900 },
  { src: photo5, width: 800, height: 750 },
  { src: photo12, width: 800, height: 900 },
  { src: photo7, width: 800, height: 600 },
  { src: photo8, width: 800, height: 600 },
  { src: photo9, width: 800, height: 900 },
  { src: photo10, width: 800, height: 500 },
  // Add more photos here with their dimensions
];

const MasonryLayout = () => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };

  const closePopup = () => {
    setSelectedPhoto(null);
  };

  const renderPhoto = ({ photo, index }) => (
    <LazyImage
      photo={photo}
      alt={`Photo ${index + 1}`}
      onClick={() => handlePhotoClick(photo)}
    />
  );

  const renderPhoto1 = ({ photo, index }) => (
    <LazyImage
      photo={photo}
      alt={`Photo ${index + 1}`}
      onClick={() => handlePhotoClick(photo)}
    />
  );

  return (
    <>
      <div className='menu'>
        <Link className='item01' to="/"><p className='item1'>Home</p></Link>
        <Link className='item02' to="/contact"><p className='item2'>Contact Us</p></Link>
        <Link className='item03' to="/gallery"><p className='item3'>Gallery</p></Link>
      </div>
      <div className='image-container'>
        <img src={contactImage} alt="Contact" className='contact-image2' />
      </div>
      <div className='back2'></div>
      <div className='promises0'>
        <h1>PHOTO ALBUM</h1><MdPhotoAlbum size={85} className='picon1' />
      </div>
      <div className="masonry-layout">
        <PhotoAlbum
          layout="masonry"
          photos={photos}
          renderPhoto={renderPhoto}
          columns={(containerWidth) => {
            if (containerWidth < 500) return 2;
            if (containerWidth < 900) return 3;
            return 4;
          }}
          spacing={10}
        />
      </div>
      <hr className='hrline3' />
      <div className='heading'><h1>MAIN HALL</h1></div>
      <div className="masonry-layout">
        <PhotoAlbum
          layout="masonry"
          photos={photos1}
          renderPhoto={renderPhoto1}
          columns={(containerWidth) => {
            if (containerWidth < 500) return 2;
            if (containerWidth < 900) return 3;
            return 4;
          }}
          spacing={10}
        />
      </div>
      <hr className='hrline3' />
      <div className='promises0'>
        <h1>VIDEO</h1>
      </div>
      <div className="video-container">
        <video width="1800" height="450" controls>
          <source src={videoFile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <hr className='hrline4' />
      <div className='footer1'>
        <div className='image-container1'>
          <img src={contactImage} alt="Contact" className='contact-image1' />
        </div>
        <h1>SST CONVENTION HALL</h1>
      </div>
      {selectedPhoto && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-btn" onClick={closePopup}>×</span>
            <img src={selectedPhoto.src} alt="Popup" className="popup-image" />
          </div>
        </div>
      )}
    </>
  );
};

export default MasonryLayout;
