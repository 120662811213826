import React, { useState, useEffect, useRef } from "react";
import { InstagramEmbed } from 'react-social-media-embed';
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import ErrorBoundary from './ErrorBoundary';
import './InstaPosts.css';

function InstagramPost() {
    const urls = [
        'https://www.instagram.com/p/B13bptUldK3/',
        'https://www.instagram.com/p/B1StVGMl3Dr/',
        'https://www.instagram.com/p/B1SslwQFqZv/',
        'https://www.instagram.com/p/CDIYEAsh7hF/',
        'https://www.instagram.com/p/B1Ss4SEldCN/',
        'https://www.instagram.com/p/B1SsQBYFeyv/',
        'https://www.instagram.com/p/B1Ssbkmlfoo/',
        'https://www.instagram.com/p/B1SsL0DF0tS/',
        'https://www.instagram.com/p/B1SsuNFlSBl/',
        'https://www.instagram.com/p/B1Ssbkmlfoo/'
    ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const [interacted, setInteracted] = useState(false);
    const [slidesToShow, setSlidesToShow] = useState(4);
    const sliderRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 576) {
                setSlidesToShow(1); // Show 1 slide for small screens
            } else {
                setSlidesToShow(4); // Show 4 slides for larger screens
            }
        };

        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!interacted && window.innerWidth > 576) {
            const interval = setInterval(() => {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % urls.length);
                sliderRef.current.scrollBy({ left: sliderRef.current.clientWidth / slidesToShow, behavior: 'smooth' });
            }, 5000); // Adjust the interval duration (in milliseconds)

            return () => clearInterval(interval);
        }
    }, [urls.length, interacted, slidesToShow]);

    const slideLeft = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + urls.length) % urls.length);
        setInteracted(true); // User interaction detected
        sliderRef.current.scrollBy({ left: -sliderRef.current.clientWidth / slidesToShow, behavior: 'smooth' });
    };

    const slideRight = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % urls.length);
        setInteracted(true); // User interaction detected
        sliderRef.current.scrollBy({ left: sliderRef.current.clientWidth / slidesToShow, behavior: 'smooth' });
    };

    const handlePostClick = () => {
        setInteracted(true); // User interaction detected
    };

    return (
        <div className='insta'>
            <MdArrowBackIos size={35} className='arrow1' onClick={slideLeft} />
            <div className='slider' ref={sliderRef}>
                <div className='slide-container'>
                    {urls.map((url, index) => (
                        <div className='slide' key={index} style={{ width: `${100 / slidesToShow}%`, marginRight: '20px' }}>
                            <ErrorBoundary>
                                <InstagramEmbed url={url} onClick={handlePostClick} className="posts" />
                            </ErrorBoundary>
                        </div>
                    ))}
                </div>
            </div>
            <MdArrowForwardIos size={35} className='arrow2' onClick={slideRight} />
        </div>
    );
}

export default InstagramPost;
