import React from 'react';
import { useInView } from 'react-intersection-observer';

const LazyImage = ({ photo, alt,onClick }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      className="photo-wrapper"
      style={{ padding: '5px' }}
      ref={ref}
    >
      {inView ? (
        <img
          src={photo.src}
          alt={alt}
          style={{
            width: '100%',
            height: 'auto',
            border: '3px solid rgb(178, 144, 184)',
            borderRadius: '8px',
          }}
          onClick={onClick}
        />
      ) : (
        <div
          style={{
            width: photo.width,
            height: photo.height,
            backgroundColor: '#f0f0f0',
            border: '3px solid rgb(178, 144, 184)',
            borderRadius: '8px',
          }}
        />
      )}
    </div>
  );
};

export default LazyImage;
