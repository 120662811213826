import React from 'react';
import './header.css';
import InstagramPost from './InstaPosts';
import { FaSquareInstagram } from "react-icons/fa6";
import { LuHeartHandshake } from "react-icons/lu";
import { Link } from 'react-router-dom';
import contactImage from './contactImage.png';
function headerJs(){

return(
<>
<div className='menu'>
    <Link className='item01' to="/"><p className='item1'>Home</p></Link>
    <Link className='item02' to="/contact"><p className='item2'>Contact Us</p></Link>
    <Link className='item03' to="/gallery"><p className='item3'>Gallery</p></Link>
</div>
<div className='back'></div>
<div className='title'>
    <p>SST CONVENTION HALL</p>
</div>
<div className='image-container'>
          <img src={contactImage} alt="Contact" className='contact-image3' />
        </div>
<div className='content'><p className='content1'>Welcome to SST Convention 
Hall - Your Premier Event Destination </p>
<p className='entry'>
<p>Nestled in a prime location, our state-of-the-art facility is perfect for conferences, weddings, corporate events, and more.
</p>
<p> With versatile spaces, advanced audio-visual equipment, and exceptional catering services, we ensure your event is a grand success.</p>
<p>Our dedicated team is committed to delivering unparalleled experiences tailored to your needs.</p>
<p>
Convenient parking and easy accessibility make SST Convention Hall the top choice for unforgettable events.</p>
</p>
</div>
<br/>
<div>
<hr className='hrline2'/>
<div className='promises'>
<h1>OUR PROMISES</h1>
<LuHeartHandshake size={35} className='picon'/></div></div>
<div className='cards'>
<div className='one'>
<p className='overlay'>Elegancy</p>
<img className='card1' src="card1.png" alt = "wedding" height={280} width={500}/>
<p className='text1'><p>Step into a realm where every detail</p><p>whispers sophistication and every corner</p>
<p>exudes timeless charm.</p> Welcome to SST Convention Hall,<p> where elegance finds its 
truest expression.</p></p>
</div>
<div className='two'>
<p className='overlay1'>Professional</p>
<img className='card2' src="card2.png" alt = "wedding" height={280} width={500}/>
<p className='text2'>At SST Convention Hall,<p>we redefine professionalism to ensure</p>
<p>your event is executed with utmost</p>precision and finesse.
<p>As a premier event destination</p><p> in the heart of Bengaluru,</p>
<p> we take pride in offering a seamless </p><p>experience tailored to your needs.</p></p>
</div>
<div className='three'>
<p className='overlay2'>Peace</p>
<img className='card3' src="card3.png" alt = "wedding" height={280} width={500}/>
<p className='text3'><p>With spacious interiors, lush greenery,</p><p> and impeccable service,</p>
<p> SST Convention Hall sets the stage </p>
<p>for unforgettable experiences filled</p> with peace and harmony.</p>
</div>
</div>
<br/>
<div className='instagram'>
     <div className='instatitle'>
    <h1>INSTA FEEDS</h1>
    <FaSquareInstagram size={35} className='instaicon'></FaSquareInstagram>
     </div>
    <p className='hashtags'>#SSTConventionHall #EventVenue #CorporateEvents #Weddings #SpecialEvents #EventPlanning #BookNow</p>
     <hr className='hrline'/>
    <div className='insta'>
    <InstagramPost></InstagramPost>
    </div>
    <hr className='hrline1'/>
              </div>
   <div className='footer0'>
        <div className='image-container1'>
          <img src={contactImage} alt="Contact" className='contact-image1' />
        </div>
        <h1>SST CONVENTION HALL</h1>
</div>
</>
);
}

export default headerJs;