// LetterByLetter.js
import React, { useState, useEffect } from 'react';
import './LetterByLetter.css';
import { useInView } from 'react-intersection-observer'; // If you have styles for the component

const LetterByLetter = ({ text, speed }) => {
  const [visibleText, setVisibleText] = useState('');
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the component is visible
  });

  useEffect(() => {
    if (inView) {
    let index = 0;
    const interval = setInterval(() => {
      if (index < text.length-1) {
        setVisibleText((prev) => prev + text[index]);
        index++;
      } else {
        clearInterval(interval); // Clear interval when all characters are displayed
        setVisibleText(text); // Set visibleText to the entire text
      }
    }, speed);

    return () => clearInterval(interval);
 } // Cleanup interval on component unmount
  }, [text, speed,inView]);
  const displayedText = `'${visibleText}'`;
  return <div className="letter-by-letter"  ref={ref}>{displayedText}</div>;
};

export default LetterByLetter;
